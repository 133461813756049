"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarFigurePartType = void 0;
var AvatarFigurePartType;
(function (AvatarFigurePartType) {
    AvatarFigurePartType["Body"] = "bd";
    AvatarFigurePartType["Shoes"] = "sh";
    AvatarFigurePartType["Legs"] = "lg";
    AvatarFigurePartType["Chest"] = "ch";
    AvatarFigurePartType["WaistAccessory"] = "wa";
    AvatarFigurePartType["ChestAccessory"] = "ca";
    AvatarFigurePartType["Head"] = "hd";
    AvatarFigurePartType["Hair"] = "hr";
    AvatarFigurePartType["FaceAccessory"] = "fa";
    AvatarFigurePartType["EyeAccessory"] = "ea";
    AvatarFigurePartType["HeadAccessory"] = "ha";
    AvatarFigurePartType["HeadAccessoryExtra"] = "he";
    AvatarFigurePartType["CoatChest"] = "cc";
    AvatarFigurePartType["ChestPrint"] = "cp";
    AvatarFigurePartType["LeftHandItem"] = "li";
    AvatarFigurePartType["LeftHand"] = "lh";
    AvatarFigurePartType["LeftSleeve"] = "ls";
    AvatarFigurePartType["RightHand"] = "rh";
    AvatarFigurePartType["RightSleeve"] = "rs";
    AvatarFigurePartType["Face"] = "fc";
    AvatarFigurePartType["Eyes"] = "ey";
    AvatarFigurePartType["HairBig"] = "hrb";
    AvatarFigurePartType["RightHandItem"] = "ri";
    AvatarFigurePartType["LeftCoatSleeve"] = "lc";
    AvatarFigurePartType["RightCoatSleeve"] = "rc";
})(AvatarFigurePartType = exports.AvatarFigurePartType || (exports.AvatarFigurePartType = {}));
