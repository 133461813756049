"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarAction = void 0;
var AvatarAction;
(function (AvatarAction) {
    AvatarAction["Move"] = "Move";
    AvatarAction["Wave"] = "Wave";
    AvatarAction["Talk"] = "Talk";
    AvatarAction["Swim"] = "Swim";
    AvatarAction["Float"] = "Float";
    AvatarAction["Sign"] = "Sign";
    AvatarAction["Respect"] = "Respect";
    AvatarAction["Blow"] = "Blow";
    AvatarAction["Laugh"] = "Laugh";
    AvatarAction["SnowWarRun"] = "SnowWarRun";
    AvatarAction["SnowWarDieFront"] = "SnowWarDieFront";
    AvatarAction["SnowWarDieBack"] = "SnowWarDieBack";
    AvatarAction["SnowWarPick"] = "SnowWarPick";
    AvatarAction["SnowWarThrow"] = "SnowWarThrow";
    AvatarAction["Lay"] = "Lay";
    AvatarAction["Sit"] = "Sit";
    AvatarAction["Idle"] = "Idle";
    AvatarAction["Dance"] = "Dance";
    AvatarAction["UseItem"] = "UseItem";
    AvatarAction["CarryItem"] = "CarryItem";
    AvatarAction["Gesture"] = "Gesture";
    AvatarAction["GestureSmile"] = "GestureSmile";
    AvatarAction["GestureSad"] = "GestureSad";
    AvatarAction["GestureAngry"] = "GestureAngry";
    AvatarAction["GestureSurprised"] = "GestureSurprised";
    AvatarAction["Sleep"] = "Sleep";
    AvatarAction["Default"] = "Default";
})(AvatarAction = exports.AvatarAction || (exports.AvatarAction = {}));
