"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FurnitureHelper = void 0;
const PIXI = __importStar(require("pixi.js"));
const BaseFurniture_1 = require("./BaseFurniture");
class FurnitureHelper {
    static getFurniPreview(furniId, shroom) {
        return new Promise((resolve, reject) => {
            const container = new PIXI.Container();
            BaseFurniture_1.BaseFurniture.fromShroom(shroom, container, {
                animation: "0",
                direction: 2,
                type: { type: furniId, kind: "type" },
                onLoad: () => {
                    const image = shroom.dependencies.application.renderer.plugins.extract.image(container);
                    image.onload = () => {
                        resolve(image);
                        container.destroy();
                    };
                },
            });
        });
    }
    static async getFurniOptions(furni, shroom) {
        var _a;
        if (!furni.type)
            return; // If Furniture Type == Undefined, Return Undefined
        const info = await ((_a = shroom.dependencies.furnitureData) === null || _a === void 0 ? void 0 : _a.getInfo(furni.type));
        if (!info)
            return; // If FurnitureData Fetch Fails, Return Undefined
        const image = (await this.getFurniPreview(furni.type, shroom)).src;
        const validDirections = await furni.validDirections;
        return {
            name: info === null || info === void 0 ? void 0 : info.name,
            type: furni.type,
            description: info === null || info === void 0 ? void 0 : info.description,
            image,
            move: (callback) => { callback(); },
            rotate: () => { furni.rotate(); },
            pickup: (callback) => { callback(); },
        };
    }
}
exports.FurnitureHelper = FurnitureHelper;
