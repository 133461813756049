"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tile = void 0;
const PIXI = __importStar(require("pixi.js"));
const matrixes_1 = require("../matrixes");
class Tile extends PIXI.Container {
    get color() {
        return this._color;
    }
    set color(value) {
        this._color = value;
        this._updateSprites();
    }
    get tilePositions() {
        return this._tilePositions;
    }
    set tilePositions(value) {
        this._tilePositions = value;
        this._updateSprites();
    }
    get tileHeight() {
        return this._tileHeight;
    }
    set tileHeight(value) {
        this._tileHeight = value;
        this._updateSprites();
    }
    constructor(props) {
        super();
        this.props = props;
        this._tilePositions = new PIXI.Point(0, 0);
        this._showBorders = { showLeftBorder: true, showRightBorder: true };
        this._texture = props.texture;
        this._color = props.color;
        this._tileHeight = props.tileHeight;
        this._showBorders = props.showBorders;
        this._updateSprites();
    }
    update(data) {
        this.tileHeight = data.tileHeight;
        this._roomPartData = data;
        this._texture = data.tileTexture;
        this._updateSprites();
    }
    _updateSprites() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        this.removeChildren();
        const tileMatrix = (0, matrixes_1.getFloorMatrix)(0, 0);
        const top = new PIXI.Graphics()
            .beginTextureFill({
            texture: (_a = this._texture) !== null && _a !== void 0 ? _a : PIXI.Texture.WHITE,
            color: (_c = (_b = this._roomPartData) === null || _b === void 0 ? void 0 : _b.tileTopColor) !== null && _c !== void 0 ? _c : 0,
            matrix: new PIXI.Matrix(1, 0.5, 1, -0.5, 0, 0)
        })
            .moveTo(0, 0)
            .lineTo(32, -16)
            .lineTo(64, 0)
            .lineTo(32, 16)
            .lineTo(0, 0)
            .endFill();
        top.position.set(tileMatrix.tx, tileMatrix.ty);
        this.addChild(top);
        if (this._showBorders.showLeftBorder) {
            const borderLeftMatrix = (0, matrixes_1.getLeftMatrix)(0, 0, {
                width: 32,
                height: this.tileHeight,
            });
            const left = new PIXI.Graphics()
                .beginTextureFill({
                texture: (_d = this._texture) !== null && _d !== void 0 ? _d : PIXI.Texture.WHITE,
                color: (_f = (_e = this._roomPartData) === null || _e === void 0 ? void 0 : _e.tileLeftColor) !== null && _f !== void 0 ? _f : 0,
                matrix: borderLeftMatrix
            })
                .moveTo(0, 0)
                .lineTo(0, this.tileHeight)
                .lineTo(32, 16 + this.tileHeight)
                .lineTo(32, 16)
                .endFill();
            left.position.set(0, 16);
            this.addChild(left);
        }
        if (this._showBorders.showRightBorder) {
            const borderRightMatrix = (0, matrixes_1.getRightMatrix)(0, 0, {
                width: 32,
                height: this.tileHeight,
            });
            const right = new PIXI.Graphics()
                .beginTextureFill({
                texture: (_g = this._texture) !== null && _g !== void 0 ? _g : PIXI.Texture.WHITE,
                color: (_j = (_h = this._roomPartData) === null || _h === void 0 ? void 0 : _h.tileRightColor) !== null && _j !== void 0 ? _j : 0,
                matrix: borderRightMatrix
            })
                .moveTo(32, 16)
                .lineTo(32, 16 + this.tileHeight)
                .lineTo(64, this.tileHeight)
                .lineTo(64, 0)
                .lineTo(32, 16)
                .endFill();
            right.position.set(0, 16);
            this.addChild(right);
        }
    }
}
exports.Tile = Tile;
